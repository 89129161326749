.accordion button p {
  line-height: 100%;
}

.accordion.line-secondary button {
  border-bottom: 1px solid #1d4045;
}

.accordion-row {
  color: #1d4045;
  cursor: pointer;
  width: 100%;
  text-align: left;
  border: none;
  outline: none;
  padding: 15px 0 10px;
  font-weight: bold;
  transition-duration: 1s;
}

.accordion-arrow {
  width: 25px;
  transition-duration: 1.5s;
  display: inline;
  position: absolute;
  right: 0;
}

.accordion-arrow.arrow-inner {
  right: 45px;
}

.panel.slide {
  max-height: 1500px;
  transition: max-height 1.5s ease-in;
}

.panel {
  max-height: 0;
  background-color: #fff;
  padding: 0 18px;
  transition: max-height 1.5s ease-out;
  overflow: hidden;
}

#mobile-footer-accordion {
  margin-bottom: 20px;
}

#mobile-footer-accordion .accordion .card .card-content div ul {
  margin: 0;
}

#mobile-footer-accordion .accordion .card .card-content div ul li {
  margin-bottom: 12px;
}

#mobile-footer-accordion .accordion .card .card-content div ul li a {
  color: #fff;
}

#mobile-footer-accordion .accordion .card {
  background-color: #1d4045;
  border-top: none;
  border-bottom: 1px solid gray;
}

#mobile-footer-accordion .accordion .card .card-header .card-header-icon svg > * {
  fill: #fff !important;
}

#mobile-footer-accordion .accordion .card.card-active .card-header .card-header-icon svg > * {
  fill: #ffaa2b !important;
}

#mobile-footer-accordion .panel.slide {
  max-height: 500px;
  transition: all 1s ease-in;
}

#mobile-footer-accordion .panel {
  max-height: 0;
  -o-transition: all 1s ease-in-out;
  background-color: #1d4045;
  padding: 0;
  transition: all 1s ease-in-out;
  overflow: hidden;
}

#mobile-footer-accordion .accordion-row {
  color: #fff;
  cursor: pointer;
  text-align: left;
  border: none;
  outline: none;
  padding: 25px 0;
  font-weight: bold;
}

#mobile-footer-accordion .accordion {
  border-top: 1px solid #d3d3d3;
}

#mobile-footer-accordion .accordion button p {
  line-height: 100%;
}

#mobile-footer-accordion .accordion:last-child {
  border-bottom: 1px solid #d3d3d3;
}

#mobile-footer-accordion .accordion.line-secondary {
  border-top: 1px solid #3e8b95;
}

#mobile-footer-accordion .accordion.line-secondary:last-child {
  border-bottom: 1px solid #3e8b95;
}

/*# sourceMappingURL=index.css.map */
